.main {
  text-align: center;
  margin: auto;
  width: 80%;
}

@media (min-width: 768px) {
  .main {
    width: 60%;
  }
}

footer {
  margin-top: 30px;
}

input {
  border: none;
  outline: none;
  background: #eee;
  margin-left: 2px;
  margin-right: 2px;
  border-bottom: 1px solid #e5e5e5;
}
